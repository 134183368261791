<template>
  <div>
    <b-row>
      <b-col
        sm="6"
        md="3"
        lg="3"
      >
        <b-card
          class="text-center"
        >
          <h2 v-text="Number(report.ownBalance).toLocaleString()" />
          <span v-text="$t('own_balance')" />
        </b-card>
      </b-col>
      <b-col
        v-if="userData.role !== 'company' && userData.role !== 'distributor' && userData.role !== 'agent'"
        sm="6"
        md="3"
        lg="3"
      >
        <b-card
          class="text-center"
        >
          <h2 v-text="Number(report.companyBalance).toLocaleString()" />
          <span v-text="$t('company_balance')" />
        </b-card>
      </b-col>
      <b-col
        v-if="(userData.role !== 'agent' && (userData.role === 'distributor' && userData.can_create_distributor === 'Yes')) || userData.role === 'admin' || userData.role === 'company'"
        sm="6"
        md="3"
        lg="3"
      >
        <b-card
          class="text-center"
        >
          <h2 v-text="Number(report.distributorBalance).toLocaleString()" />
          <span v-text="$t('distributor_balance')" />
        </b-card>
      </b-col>
      <b-col
        v-if="userData.role !== 'agent'"
        sm="6"
        md="3"
        lg="3"
      >
        <b-card
          class="text-center"
        >
          <h2 v-text="Number(report.agentBalance).toLocaleString()" />
          <span v-text="$t('agent_balance')" />
        </b-card>
      </b-col>
      <b-col
        sm="6"
        md="3"
        lg="3"
      >
        <b-card
          class="text-center"
        >
          <h2 v-text="Number(report.memberBalance).toLocaleString()" />
          <span v-text="$t('member_balance')" />
        </b-card>
      </b-col>
      <b-col
        sm="6"
        md="3"
        lg="3"
      >
        <b-card
          class="text-center"
        >
          <h2 v-text="Number(report.totalBalance).toLocaleString()" />
          <span v-text="$t('total_balance')" />
        </b-card>
      </b-col>
      <b-col
        sm="6"
        md="3"
        lg="3"
      >
        <b-card
          class="text-center"
        >
          <h2 v-text="Number(report.todayNoOfBet).toLocaleString()" />
          <span v-text="$t('today_no_of_bet')" />
        </b-card>
      </b-col>
      <b-col
        sm="6"
        md="3"
        lg="3"
      >
        <b-card
          class="text-center"
        >
          <h2 v-text="Number(report.todayNoOfPlayer).toLocaleString()" />
          <span v-text="$t('today_no_of_player')" />
        </b-card>
      </b-col>
      <b-col
        sm="6"
        md="3"
        lg="3"
      >
        <b-card
          class="text-center"
        >
          <h2 v-text="Number(report.todayBet).toLocaleString()" />
          <span v-text="$t('today_bet')" />
        </b-card>
      </b-col>
      <b-col
        sm="6"
        md="3"
        lg="3"
      >
        <b-card
          class="text-center"
        >
          <h2 v-text="Number(report.todayRolling).toLocaleString()" />
          <span v-text="$t('today_rolling')" />
        </b-card>
      </b-col>
      <b-col
        sm="6"
        md="3"
        lg="3"
      >
        <b-card
          class="text-center"
        >
          <h2
            :class="(report.todayWinLoss < 0) ? 'text-lose-result-color' : ''"
            v-text="Number(report.todayWinLoss).toLocaleString()"
          />
          <span v-text="$t('today_win_loss')" />
        </b-card>
      </b-col>
      <b-col
        sm="6"
        md="3"
        lg="3"
      >
        <b-card
          class="text-center"
        >
          <h2 v-text="Number(onlineUsers.length).toLocaleString()" />
          <span v-text="$t('online_users')" />
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import {
  BCard,
  BRow,
  BCol,
} from 'bootstrap-vue'

import Account from '@/models/Account'
import { EventBus } from '../../eventbus'

export default {
  name: 'Dashboard',
  components: {
    BCard,
    BRow,
    BCol,
  },
  data() {
    return {
      socket: {},
      userData: JSON.parse(localStorage.getItem('userData')),
      onlineUsers: [],
      report: {
        ownBalance: 0,
        companyBalance: 0,
        distributorBalance: 0,
        agentBalance: 0,
        memberBalance: 0,
        totalBalance: 0,
        todayNoOfBet: 0,
        todayNoOfPlayer: 0,
        todayBet: 0,
        todayRolling: 0,
        todayWinLoss: 0,
      },
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    async loadData() {
      EventBus.$emit('getMyUpdatedUserData')
      const response = await Account.getDashboard()
      this.report = response.data.report
      this.onlineUsers = response.data.onlineUsers
    },
  },
}
</script>

<style>
</style>
